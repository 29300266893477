export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_WISH = "ADD_TO_WISH";

export const ADJUST_ITEM_QTY = "ADJUST_ITEM_QTY";

export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

export const LOAD_CURRENT_ITEM = "LOAD_CURRENT_ITEM";

export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const PRICE_FILTER = "PRICE_FILTER";