import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import Background from "../background";
import Header from "../header";
import Footer from "../footer";

import api from '../../../../configs/api';

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleLogin = async () => {
    try {
      const response = await api.post('/api/login', { email, password });

      // Armazenar o token no localStorage
      localStorage.setItem('token', response.data.token);

      // Armazenar o ID do usuário ou subusuário
      if (response.data.userId) {
        localStorage.setItem('userId', response.data.userId);
      }

      // Armazenar se é um subusuário
      if (response.data.isSubuser) {
        localStorage.setItem('isSubuser', 'true');
      }

      // Supondo que as permissões estão incluídas na resposta da API, sob uma chave 'permissions'
      if (response.data.permissions) {
        localStorage.setItem('userPermissions', JSON.stringify(response.data.permissions));
      }

      // Redirecionar para o dashboard ou página inicial após o login
      navigate('/dashboard');

      
    } catch (error) {
      message.error('Erro ao registrar: ' + (error.response?.data.message || 'Erro desconhecido'));
    }
};





  return (
    <Row className="hp-authentication-page hp-d-flex" style={{ flexDirection: "column" }}>
      <Background />

      <Col span={24}>
        <Header />
      </Col>

      <Col flex="1 0 0" className="hp-px-32">
        <Row className="hp-h-100 hp-m-auto" align="middle" style={{ maxWidth: 360 }}>
          <Col span={24}>
            <h1>Login</h1>

            <Form
              name="login_form"
              initialValues={{ remember: true }}
              onFinish={handleLogin}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
              >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Senha" onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Lembrar</Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" block>
                  Entrar
                </Button>
              </Form.Item>
            </Form>


          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Footer />
      </Col>
    </Row>
  );
};
