import React, { useState, useEffect } from "react";
import { NavLink as NavLinkRRD, Link, useNavigate } from "react-router-dom";

import { Divider, Avatar, Row, Col } from "antd";
import { RiSettings3Line } from "react-icons/ri";

import IntlMessages from "../../lang/IntlMessages";
import avatar from "../../../../assets/images/memoji/user-avatar-8.png";

import api from "../../../../configs/api";

export default function MenuFooter(props) {
  const navigate = useNavigate();
  const [userPlan, setUserPlan] = useState('Carregando...');
  const [userName, setUserName] = useState('Carregando...');

  useEffect(() => {

    const fetchUserName = async () => {
      try {
        // Substitua '/api/user-info' pela URL correta da sua API
        const response = await api.get('/api/user-info', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setUserPlan(response.data.subscriptionPlan);
        setUserName(response.data.name);
      } catch (error) {
        console.error("Erro ao buscar nome do usuário", error);
        setUserPlan('Usuário Desconhecido'); // Ou lidar de outra forma com o erro
      }
    };

    fetchUserName();
  }, []);

  const getUserPlanColor = (plan) => {
    switch (plan.toLowerCase()) { // Convert to lowercase to ensure case-insensitive comparison
        case 'free':
            return '#008000'; // Green for Free
        case 'basico':
            return '#0000FF'; // Blue for Basico
        case 'pro':
            return '#000000'; // Black for Pro
        default:
            return null; // Default color or null if not needed
    }
};



  return (
    props.collapsed === false ? (
      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify="space-between"
      >
        <Divider className="hp-border-color-black-40 hp-border-color-dark-70 hp-mt-0" />

        <Col>
          <Row align="middle">


            <div className="hp-mt-6">
            
              <span className="hp-d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body" style={{ lineHeight: 1 }}>
              {userName || 'Carregando...'}
              </span>

              
            </div>
          </Row>
        </Col>

        <Col>
          <Link
            to="/personel-information"
            onClick={props.onClose}
          >
            <RiSettings3Line
              className="remix-icon hp-text-color-black-100 hp-text-color-dark-0"
              size={24}
            />
          </Link>
        </Col>
      </Row>
    ) : (
      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify="center"
      >
        <Col>
          <Link
            to=""
            onClick={props.onClose}
          >
            <Avatar size={48} src={avatar} className="hp-bg-info-4" />
          </Link>
        </Col>
      </Row>
    )
  );
};