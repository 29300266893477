import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Col, Divider, Row } from "antd";
import { UserOctagon, Flag, Calendar, Calculator } from 'iconsax-react';
import api from "../../../configs/api";


export default function HeaderUser() {
  const navigate = useNavigate();
 
  const [userPlan, setUserPlan] = useState('Carregando...');
  const [userName, setUserName] = useState('Carregando...');

  useEffect(() => {

    const fetchUserName = async () => {
      try {
        // Substitua '/api/user-info' pela URL correta da sua API
        const response = await api.get('/api/user-info', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setUserPlan(response.data.subscriptionPlan);
        setUserName(response.data.name);
      } catch (error) {
        console.error("Erro ao buscar nome do usuário", error);
        setUserPlan('Usuário Desconhecido'); // Ou lidar de outra forma com o erro
      }
    };

    fetchUserName();
  }, []);

  const getUserPlanColor = (plan) => {
    switch (plan.toLowerCase()) { // Convert to lowercase to ensure case-insensitive comparison
        case 'free':
            return '#008000'; // Green for Free
        case 'basico':
            return '#0000FF'; // Blue for Basico
        case 'pro':
            return '#000000'; // Black for Pro
        default:
            return null; // Default color or null if not needed
    }
};

const handleLogout = () => {
  // Remover token e permissões do localStorage
  localStorage.removeItem('token');
  localStorage.removeItem('userPermissions');

  // Redirecionar para a página de login ou inicial
  navigate('/login');
};
  
  const menu = (
    <div className="hp-user-dropdown hp-border-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-py-24 hp-px-18 hp-mt-16">
      <span className="hp-d-block h5 hp-font-weight-500 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-16">Configuração</span>
     
      <Link
        to="/personel-information"
        className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
      >
        Meu Perfil
      </Link>


     
      
      <Divider className="hp-mb-18 hp-mt-12" />

      

     
      
      <Col span={24}>
      
        <button onClick={handleLogout}>Sair</button>
        </Col>
      
    </div>
    
  );

  

  return (
    <Col className="hp-ml-8">
    <Dropdown overlay={menu} placement="bottomLeft">
      <div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
        <div className="hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex hp-justify-content-center hp-align-items-center" style={{ minWidth: 32, width: 32, height: 32 }}>
          <i className="ri-account-circle-line" style={{ fontSize: '32px' }} />
        </div>
      </div>
    </Dropdown>
  </Col>
  
  );
};
