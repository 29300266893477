import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Menu, Tag } from "antd";
import navigation from "../../../../navigation/vertical";
import usePermissionChecker from "../../../../configs/usePermissionChecker";

const { SubMenu } = Menu;

const MenuItem = (props) => {
    const { onClose } = props;

    // Redux
    const customise = useSelector(state => state.customise);

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/");

    // Menu
    const splitLocationUrl = `${splitLocation[splitLocation.length - 2]}/${splitLocation[splitLocation.length - 1]}`;

    const renderMenuItem = (item) => {
        const hasPermission = usePermissionChecker(item.permissions || []);

        if (item.header) {
            return <Menu.ItemGroup key={item.header} title={item.header}></Menu.ItemGroup>;
        }

        if (item.children) {
            const filteredChildren = item.children.filter(childItem => usePermissionChecker(childItem.permissions || []));

            if (filteredChildren.length === 0) {
                return null; // Se nenhum filho tiver permissão, não renderize o submenu
            }

            return (
                <SubMenu key={item.id} icon={item.icon} title={item.title}>
                    {filteredChildren.map((childItem) => (
                        <Menu.Item
                            key={childItem.id}
                            onClick={onClose}
                            className={splitLocationUrl === `${childItem.navLink.split("/").slice(-2).join("/")}` ? "ant-menu-item-selected" : "ant-menu-item-selected-in-active"}
                        >
                            <Link to={childItem.navLink}>{childItem.title}</Link>
                        </Menu.Item>
                    ))}
                </SubMenu>
            );
        } else {
            const itemNavLink = item.navLink ? item.navLink.split("/") : '';

            return hasPermission ? (
                <Menu.Item
                    key={item.id}
                    icon={item.icon}
                    onClick={onClose}
                    className={splitLocationUrl === `${itemNavLink[itemNavLink.length - 2]}/${itemNavLink[itemNavLink.length - 1]}` ? "ant-menu-item-selected" : "ant-menu-item-selected-in-active"}
                    style={item.tag && { pointerEvents: 'none' }}
                >
                    {item.tag ? (
                        <a href="#" className="hp-d-flex hp-align-items-center hp-d-flex-between">
                            <span>{item.title}</span>
                            <Tag className="hp-mr-0 hp-border-none hp-text-color-black-100 hp-bg-success-3 hp-border-radius-full hp-px-8" style={{ marginRight: -14 }}>{item.tag}</Tag>
                        </a>
                    ) : (
                        <Link to={item.navLink}>{item.title}</Link>
                    )}
                </Menu.Item>
            ) : null;
        }
    };

    const renderedMenuItems = navigation.map((item) => renderMenuItem(item));

    return (
        <Menu
            mode="inline"
            defaultOpenKeys={[splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null, splitLocation[splitLocation.length - 2]]}
            theme={customise.theme === "light" ? "light" : "dark"}
            className="hp-bg-black-20 hp-bg-dark-90"
        >
            {renderedMenuItems}
        </Menu>
    );
};

export default MenuItem;
