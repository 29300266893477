import enLang from "./entries/en_US";
import frLang from "./entries/fr_FR";
import esLang from "./entries/es_ES";

const AppLocale = {
    en: enLang,
    fr: frLang,
   
    es: esLang
};

export default AppLocale;