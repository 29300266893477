import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import usePermissionChecker from './usePermissionChecker'; // Importe o hook de verificação de permissões

const useAuth = () => {
    const token = localStorage.getItem('token');
    return !!token; // Retorna true se o token existir, false caso contrário
};

const ProtectedRoute = ({ children, permissions = [] }) => {
    const isAuthenticated = useAuth();
    const hasRequiredPermissions = usePermissionChecker(permissions);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isAuthenticated !== undefined && hasRequiredPermissions !== undefined) {
            setIsLoading(false);
        }
    }, [isAuthenticated, hasRequiredPermissions]);

    console.log("Is Authenticated:", isAuthenticated); // Depuração
    console.log("Has Required Permissions:", hasRequiredPermissions); // Depuração

    if (isLoading) {
        return <div>Loading...</div>; // Ou algum componente de carregamento
    }

    if (!isAuthenticated) {
        console.log('Redirecting to login...');
        return <Navigate to="/" replace />;
    }

    if (!hasRequiredPermissions) {
        console.log('Redirecting to unauthorized...');
        return <Navigate to="/naoautorizado" replace />;
    }

    return children;
};

export default ProtectedRoute;
