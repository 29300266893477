import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { motion } from 'framer-motion';
import { jwtDecode } from 'jwt-decode'; // Importe a função jwtDecode
import MenuLogo from "./logo";
import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";

const { Sider } = Layout;

export default function Sidebar(props) {
    const { visible, setVisible } = props;
    const [collapsed, setCollapsed] = useState(false);
    const [isSubuser, setIsSubuser] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token'); // Obtenha o token do localStorage
        if (token) {
            const decodedToken = jwtDecode(token); // Decodifique o token
            const isSubuser = decodedToken.isSubuser; // Obtenha o status de subusuário do token decodificado
            setIsSubuser(isSubuser);
        }
    }, []);

    const location = useLocation();

    const onClose = () => setVisible(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={316}
            className="hp-sidebar hp-bg-black-20 hp-bg-color-dark-90 hp-border-right-1 hp-border-color-black-40 hp-border-color-dark-80"
        >
            <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
                className="hp-d-flex hp-h-100"
                style={{ flexDirection: 'column' }}
            >
                <Row align="bottom" justify="space-between">
                    <Col>{!collapsed && <MenuLogo onClose={onClose} />}</Col>
                    {collapsed ? (
                        <Col span={24} className="hp-d-flex-full-center">
                            <div className="hp-cursor-pointer" onClick={toggle}><CaretRightOutlined /></div>
                        </Col>
                    ) : (
                        <Col className="hp-pr-0">
                            <div className="hp-cursor-pointer" onClick={toggle}><CaretLeftOutlined /></div>
                        </Col>
                    )}
                    {collapsed && <Col span={24} className="hp-mt-12 hp-d-flex-full-center">
                        <MenuLogo onClose={onClose} small={true} /></Col>}
                </Row>
                <MenuItem onClose={onClose} />
                {!isSubuser && <MenuFooter onClose={onClose} collapsed={collapsed} />} {/* Verificação para renderizar o rodapé apenas se não for um subusuário */}
                <MenuMobile onClose={onClose} visible={visible} />
            </motion.div>
        </Sider>
    );
}
