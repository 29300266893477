import { useEffect, useState } from 'react';

const usePermissionChecker = (requiredPermissions) => {
  const [hasRequiredPermissions, setHasRequiredPermissions] = useState(false);

  useEffect(() => {
    const userPermissions = JSON.parse(localStorage.getItem('userPermissions')) || [];
    const hasPermissions = requiredPermissions.every(permission => 
      userPermissions.map(p => p.toLowerCase()).includes(permission.toLowerCase())
    );
    setHasRequiredPermissions(hasPermissions);
  }, [requiredPermissions]);

  return hasRequiredPermissions;
};

export default usePermissionChecker;
