import { Award,Shop,Truck,Notepad2,Send ,WalletMoney,MobileProgramming,ReceiptText,ShoppingBag,HomeTrendUp} from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
    {
        header: <IntlMessages id="sidebar-pages" />,
        
    },
    {
        id: "Cadastrar-Pesquisar",
        title: <IntlMessages id="Cadastrar Pesquisa" />,
        icon: <MobileProgramming size={18} />,
        navLink: "/pages/cadastrar",
        permissions: ["cadastrar"],
        
    },
    {
        id: "Pesquisas-Cadastradas",
        title: <IntlMessages id="Pesquisas-Cadastradas" />,
        icon: <MobileProgramming size={18} />,
        navLink: "/pages/pesquisa-cadastrada",
        permissions: ["supervisor"]
    },
    
   
    {
        id: "Relatorios",
        title: <IntlMessages id="Relatorios" />,
        icon: <MobileProgramming size={18} />,
        permissions: ["relatorios"],
        navLink: "/pages/pesquisa-finalizada",
        
    },
    {
        id: "Responder-Pesaquisas",
        title: <IntlMessages id="Responder-Pesaquisas" />,
        icon: <MobileProgramming size={18} />,
        navLink: "/pages/responder",
        permissions: ["usuario"]
    },
    {
        id: "Criar-Usuarios",
        title: <IntlMessages id="Criar Usuarios" />,
        icon: <MobileProgramming size={18} />,
        navLink: "/pages/usuarios",
        permissions: ["supervisor"]
    },
   
   
   
    {
        id: "Administrativo",
        title: <IntlMessages id="Administrativo" />,
        icon: <HomeTrendUp size={18} />,
        navLink: "/pages/Administrativo",
        permissions: ["administrativo"]
    },
    
];

export default pages