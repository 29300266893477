import { lazy } from "react";

const PagesRoutes = [
  // Cadastrar
  {
    path: "/pages/cadastrar",
    component: lazy(() => import("../../view/pages/comercial")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["Cadastrar"],
  },
  




  // cadastrados
  {
    path: "/pages/pesquisa-cadastrada",
    component: lazy(() => import("../../view/pages/logistica")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["Supervisor"]
  },







 


  //Finalizados
  {
    path: "/pages/pesquisa-finalizada",
    component: lazy(() => import("../../view/pages/financeiro/index")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["Relatorios"]
  },
  
  
  // Responder
  {
    path: "/pages/responder",
    component: lazy(() => import("../../view/pages/rh")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["Usuario"]
  },

  
  {
    path: "/pages/ResponderPesquisa/:id",
    component: lazy(() => import("../../view/pages/respoderPesquisa/maintenance")),
    layout: "FullLayout",
    permissions: ["Usuario"],
  },

  {
    path: "/relatorio-page/:id",
    component: lazy(() => import("../../view/pages/relatorios/paginaRelatorio")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["Relatorios"],
  },


  {
    path: "/pages/usuarios",
    component: lazy(() => import("../../view/pages/subusuario/index")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["Supervisor"]
  },

  
 


 

  // ADM
  {
    path: "/pages/Administrativo",
    component: lazy(() => import("../../view/pages/adm")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },



  // Eroo
  {
    path: "/thank-you",
    component: lazy(() => import("../../view/pages/authentication-modern/thankyouPage/index")),
    layout: "FullLayout",
  },
  {
    path: "/thank-you-public",
    component: lazy(() => import("../../view/pages/authentication-modern/thankyouPage/thankyoupage")),
    layout: "FullLayout",
  },
  {
    path: "/error",
    component: lazy(() => import("../../view/pages/errors/ErrorPage")),
    layout: "FullLayout",
  },
  

  {
    path: "/pages/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },



  // Login

  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication-modern/login")),
    layout: "FullLayout",
  },



  //Usuarios

  {
    path: "/personel-information",
    component: lazy(() => import("../../view/pages/profile/personel-information")),
    layout: "VerticalLayout",
    isProtected: true,
  },


];

export default PagesRoutes;